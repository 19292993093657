import {merge} from "theme-ui"
import tailwind from "@theme-ui/preset-tailwind"

const config = merge(tailwind, {
    config: {
        initialColorModeName: `light`,
    },
    colors: {
        primary: '#66C18E',
        secondary: tailwind.colors.indigo[6],
        modes: {
            light: {
                text: tailwind.colors.black,
                primary: '#66C18E',
                background: tailwind.colors.black,
                textMuted: tailwind.colors.gray[5],
            },
            dark: {
                text: tailwind.colors.black,
                primary: '#66C18E',
                background: tailwind.colors.black,
                textMuted: tailwind.colors.gray[5],
            },
        },
    },
    layout: {
        footer: {
            textAlign: `center`,
            display: `block`,
            color: `textMuted`,
            px: [2, 3],
            py: [3, 4],
        },
        header: {
            px: [3, 4],
            py: [2, 3],
            fontSize: '20px',
            display: `flex`,
            alignItems: `center`,
            flexWrap: `wrap`,
        },
        main: {
            position: `relative`,
        },
        container: {
            maxWidth: `5xl`,
            padding: 4,
        },
    },
    styles: {
        root: {
            color: `text`,
            backgroundColor: `background`,
            margin: 0,
            padding: 0,
            boxSizing: `border-box`,
            textRendering: `optimizeLegibility`,
            WebkitFontSmoothing: `antialiased`,
            MozOsxFontSmoothing: `grayscale`,
            WebkitTextSizeAdjust: `100%`,
            img: {
                borderStyle: `none`,
            },
            pre: {
                fontFamily: `monospace`,
                fontSize: `12px`,
            },
            a: {
                transition: `all 0.3s ease-in-out`,
                fontSize: '16px',
                color: '#66C18E'
            },
        },
        p: {
            fontSize: '18px',
            textAlign: 'justify',
            lineHeight: `body`,
            "--baseline-multiplier": 0.179,
            "--x-height-multiplier": 0.35,
        },
        h1: {
            fontSize: ['32px', '36px'],
            mt: 2,
            fontWeight: 'normal'
        },
        h2: {
            fontSize: ['32px', '40px'],
            mt: 2,
        },
        h3: {
            fontSize: ['32px', '40px'],
            mt: 3,
        },
        h4: {
            fontSize: ['32px', '40px'],
        },
        h5: {
            fontSize: ['16px', '20px'],
        },
        h6: {
            fontSize: '10px',
            mb: 2,
        },
    },
    buttons: {
        toggle: {
            color: `background`,
            border: `none`,
            backgroundColor: `text`,
            cursor: `pointer`,
            alignSelf: `center`,
            px: 3,
            py: 2,
            ml: 3,
        },
    },
})

export default config